import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-arrow-functions",
  "date": "2015-08-31",
  "title": "ES6 ARROW FUNCTIONS",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Arrow functionss are a function shorthand using the => syntax (fat arrow). They are syntactically similar to the related feature in C#, Java 8 and CoffeeScript. They support both expression and statement bodies. Unlike functions, arrows share the same lexical this as their surrounding code."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Message type="info" title="Scope" content="The lexical scope (this) in arrow is same as surrounding scope, unlike functions which create new scope." mdxType="Message" />
    <p>{`Let's look at a function that takes an argument written in `}<inlineCode parentName="p">{`ES5`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var sayHello = function (name) {
  return 'Hello, ' + name + '!';
}

console.log(sayHello('Sean')); // Hello, Sean!
`}</code></pre>
    <p>{`Nopw let's look at how this can be written in `}<inlineCode parentName="p">{`ES6`}</inlineCode>{` using an array function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let sayHello = name => 'Hello, ' + name + '!';

console.log(sayHello('Sean'));
`}</code></pre>
    <p>{`This may look bit confusing at the first glance, so let me explain it more clearly to you.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function(arguments) { espression }
`}</code></pre>
    <p>{`Is analogous to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`arguments=>expression
`}</code></pre>
    <p>{`So all we are really doing is removing some superfluous syntax.`}</p>
    <h2>{`Arguements`}</h2>
    <p>{`Our greeting function only accepts one argument, and when you only have one argument, parentheses are optional. Parentheses are required if you are handling more than one argument, though:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let sayHello = (firstName, lastName) => 'Hello, ' + firstname + lastName;

console.log(sayHello('Sean', 'A')); // Hello Sean A
`}</code></pre>
    <p>{`Here is what an arrow function looks like with no arguments:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let sayHello = () => 'Hello, world!';

console.log(sayHello()); // Hello world!
`}</code></pre>
    <h2>{`Anonymous`}</h2>
    <p>{`Arrow functions are always anonymous, which means we can't do this with ES6:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function doSomething() {
  ...
}
`}</code></pre>
    <p>{`Instead of this, we could assign our anonymous arrow function to a variable:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var doSomething = () => {
  ...
}
`}</code></pre>
    <h2>{`Scope`}</h2>
    <p>{`Arrow functions are not just syntactic sugar. They also change how `}<inlineCode parentName="p">{`scope`}</inlineCode>{` is handled in JavaScript. That means when every time you create a new function, it gets it's own scope.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var timer = function () {
    var self = this;

  this.seconds = 0;

  setInterval(function () {
    self.seconds++;
  }, 1000);
}
`}</code></pre>
    <p>{`We need to store the parent's `}<inlineCode parentName="p">{`this`}</inlineCode>{` so we need to store the parent's this in a local variable that we can then reference in the callback. Arrow functions inherit the current this value, so you no longer need to create a copy of your own:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var timer = function () {
  this.seconds = 0;

  setInterval(() => this.seconds++, 1000);
}
`}</code></pre>
    <h2>{`Syntax`}</h2>
    <p>{`So the syntax for arrow funcion's construct looks as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// example 1
([param] [, param]) => {
  statements
}

// example 2
param => expression
`}</code></pre>
    <p>{`Which is the equivalent ES5 of:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// example 1
function ([param] [, param]) {
  statements
}

// example 2
function (param) {
  return expression
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      